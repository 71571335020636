<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="新闻名称">
          <el-input
            placeholder="请输入新闻名称"
            v-model="queryParams.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryParams.display"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in enables"
              :key="item.value"
              
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAddNews">添加新闻</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="newsList" stripe style="width: 100%" border>
        <el-table-column
          type="selection"
          width="55px"
          align="center"
        ></el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="60px">
        </el-table-column>
        <el-table-column prop="title" label="标题" align="center">
        </el-table-column>

        <el-table-column prop="avthumb" label="缩略图" align="center" width="300px">
            <template slot-scope="scope">
                <img :src="scope.row.avthumb" alt="" style="width:200px;height:112px">
            </template>
        </el-table-column>

        <el-table-column prop="display" label="状态" align="center">
             <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.display == 1"
              @click="editEnable(scope.row.id, 0)"
            >
              显示
            </el-tag>
            <el-tag type="danger" v-else @click="editEnable(scope.row.id, 1)"
              >隐藏
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="editNews(scope.row.id)"
                circle
                size="small"
              ></el-button>
               <el-button type="danger" size="small" icon="el-icon-delete" circle @click="removeNews(scope.row.id)"></el-button>
                <el-button
                type="success"
                icon="el-icon-info"
                @click="toViewDetails(scope.row)"
                circle
                size="small"
              ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

     <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="标题：" label-width="100px">
            {{ detailsData.title }}
          </el-form-item>
          <el-form-item label="状态：" label-width="100px">
            {{detailsData.display==1?'显示':'隐藏'}}
          </el-form-item>
          <el-form-item label="创建时间：" label-width="100px">
            {{
              $moment(detailsData.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
 
          <el-form-item label="描述：" label-width="100px">
            {{detailsData.desc}}
          </el-form-item>
          <el-form-item label="缩略图：" label-width="100px">
            <img :src="detailsData.avthumb" alt="" style="height:400px;width:100%">
          </el-form-item>

        </el-form>
      </el-dialog>


     <!-- 添加新闻弹框 -->  
    <el-dialog :title="addNewsTitle" :visible.sync="addNewsDialog" width="50%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addNewsForm" :rules="rules">
        <el-form-item label="标题" label-width="100px" prop="title">
          <el-input v-model="addNewsForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="addNewsForm.desc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="来源" label-width="100px" prop="source">
          <el-input v-model="addNewsForm.source" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="缩略图" label-width="100px">
           <el-upload
            class="avatar-uploader"
            action="123"
            :show-file-list="false"
            accept="image/*"
            :before-upload="beforeThumbUpload"
          >
            <img
              v-if="thumbSrc"
              style="width: 100%"
              :src="thumbSrc"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              style="margin-top: 55px;"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" label-width="100px" prop="content">
            <quill-editor v-model="addNewsForm.content" ref="myQuillEditor" >
            </quill-editor>
        </el-form-item>
         <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isNews=='add'" @click="addNewsNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editNewsNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>



  </div>
</template>

<script>
import {
  NewsList,
  newsDisplay,
  newsDel,
  addNews,
  newsDetails,
  newsEdit,
  newCosToken,
} from "@/api/news.js";
import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import chapter from "../../components/chapter.vue";
export default {
  components: {
    quillEditor,
    chapter,
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, max: 50, message: "标题长度应在1-50字符", trigger: "blur" },
        ],
        source: [
          { required: true, message: "请输入来源", trigger: "blur" },
          { min: 1, max: 50, message: "来源长度应在1-50字符", trigger: "blur" },
        ],
        desc: [
          { required: true, message: "请输入描述", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "描述长度应在1-200字符",
            trigger: "blur",
          },
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      queryParams: {
        title: "",
        display: null,
        start_time: 0,
        end_time: 0,
        page: 1,
        limit: 10,
      },
      total: 0,
      enables: [
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      newsList: [],

      addNewsForm: {
        id: null,
        title: null,
        desc: null,
        source: null,
        content: null,
        key: null,
      },
      detailsData: {}, //详情数据
      detailsDialog: false, //详情弹框
      addNewsDialog: false,
      isNews: "add",
      addNewsTitle: "添加新闻资讯",
      thumbSrc: "",
    };
  },
  methods: {
    // 查询
    toQuery() {
      this.queryParams.page = 1;
      this.getNewsList();
    },
    // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      this.detailsDialog = true;
    },

    // 显示与隐藏
    editEnable(id, target) {
      if (target === 0) {
        warningDialog("确定要隐藏吗？").then(() => {
          newsDisplay({
            id: id,
            display: target,
          }).then((res) => {
            if (res == undefined) {
              return;
            }
            this.$message.warning("隐藏成功");
            this.getNewsList();
          });
        });
      } else {
        warningDialog("确定要显示吗？").then(() => {
          newsDisplay({
            id: id,
            display: target,
          }).then((res) => {
            if (res == undefined) {
              return;
            }
            this.$message.success("显示成功");
            this.getNewsList();
          });
        });
      }
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getNewsList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getNewsList();
    },
    // 获取新闻列表
    getNewsList() {
      NewsList(this.queryParams).then((res) => {
        this.total = res.data.total;
        this.newsList = res.data.data;
      });
    },
    // 删除新闻
    async removeNews(id) {
      const result = await this.$confirm("是否要删除该新闻?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      newsDel(id).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
        }
        this.$message.success("删除新闻成功");
        this.getNewsList();
      });
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图图不能超过 10MB!");
        return;
      }
      let that = this;
      newCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addNewsForm.key = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },
    toAddNews() {
      this.isNews = "add";
      this.addNewsDialog = true;
      this.addNewsTitle = "添加新闻资讯";
    },

    // 点击立即添加新闻
    addNewsNow() {
      addNews(this.addNewsForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加新闻成功!");
        this.getNewsList();
        this.addNewsDialog = false;
      });
    },

    // 点击修改新闻
    editNews(id) {
      this.addNewsForm.id = id;
      this.isNews = "edit";
      this.addNewsTitle = "修改新闻资讯";
      newsDetails(id).then((res) => {
        this.addNewsForm.title = res.data.title;
        this.addNewsForm.desc = res.data.desc;
        this.addNewsForm.content = res.data.content;
        this.addNewsForm.source = res.data.source;
        this.thumbSrc = res.data.avthumb;
      });
      this.addNewsDialog = true;
    },

    // 点击立即修改
    editNewsNow() {
      newsEdit(this.addNewsForm).then((res) => {
        // this.loading=false
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改成功!");
        this.addNewsDialog = false;
        this.getNewsList();
      });
    },

    closed() {
      this.addNewsForm = {
        id: null,
        title: null,
        source: null,
        desc: null,
        content: null,
        key: null,
      };
      this.thumbSrc = "";
    },
  },
  created() {
    this.getNewsList();
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
.abbreviationName {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 400px;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

::v-deep .ql-editor {
  min-height: 400px;
}
</style>
