import request from "@/utils/request";

export function NewsList(data){
    return request({
        url:'/admin/base/newsList',
        method:'get',
        params:data
        }
    )
}

export function newsDisplay(data){
    return request({
        url:'/admin/base/newsDisplay',
        method:'put',
        data
        }
    )
}

export function newsDel(data){
    return request({
        url:'/admin/base/newsDel',
        method:'delete',
        data: {
            id:data
        }
        }
    )
}

export function addNews(data){
    return request({
        url:'/admin/base/newsAdd',
        method:'post',
        data
        }
    )
}

export function newsDetails(data){
    return request({
        url:'/admin/base/newsDetails',
        method:'get',
        params: {
            id:data
        }
    })
}

export function newsEdit(data){
    return request({
        url:'/admin/base/newsEdit',
        method:'put',
        data
    })
}

// 申请上传新闻缩略图token
export function newCosToken(params){
    return request({
        url:'/admin/base/newCosToken',
        method: 'get',
        params
    })
}